import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { supabase } from '../supabaseClient'; 

const UserSettings = ({ isVisible, onClose, userId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Beim Öffnen des Modals die aktuellen Benutzerdaten laden
  useEffect(() => {
    if (isVisible) {
      // Lade die Benutzerdaten und setze sie als Anfangswerte im Formular
      const loadUserData = async () => {
        const { data, error } = await supabase
          .from('user_settings')
          .select('first_name, last_name, custom_instructions')
          .eq('user_id', userId)
          .single();
        
        if (data) {
          form.setFieldsValue(data);
        }
      };

      loadUserData();
    }
  }, [isVisible, userId, form]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const { error } = await supabase
      .from('user_settings')
      .update({
        first_name: values.first_name,
        last_name: values.last_name,
        custom_instructions: values.custom_instructions,
      })
      .eq('user_id', userId);

    setLoading(false);

    if (error) {
      alert('Es gab einen Fehler beim Aktualisieren der Benutzereinstellungen.');
      console.error('Update error:', error);
    } else {
      onClose(); // Schließe das Modal nach dem Update
    }
  };

  return (
    <Modal title="Benutzereinstellungen" visible={isVisible} onCancel={onClose} footer={null}>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item label="Vorname" name="first_name">
          <Input />
        </Form.Item>
        <Form.Item label="Nachname" name="last_name">
          <Input />
        </Form.Item>
        <Form.Item label="Anweisungen" name="custom_instructions">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserSettings;
