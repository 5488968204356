import React from 'react';
import { Input, Button, Form } from 'antd';
import { SendOutlined, StopOutlined } from '@ant-design/icons';

function ChatInput({ onSendMessage, isLoading, selectedThreadId }) {
  const [form] = Form.useForm(); // Verwenden Sie die Form-Instanz von Ant Design

  // Funktion zum Senden der Thread ID per POST
  const postThreadId = async () => {
    try {
      const response = await fetch('https://flow002.lvit.io/webhook/0e73fb39-c335-4104-bcec-0e126a90f68d', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threadId: selectedThreadId }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Thread ID erfolgreich gesendet');
    } catch (error) {
      console.error('Fehler beim Senden der Thread ID:', error);
    }
  };

  const onFinish = (values) => {
    if (isLoading) {
      postThreadId();
    } else {
      // Nur senden, wenn die Nachricht nicht leer ist
      if (values.message && values.message.trim()) {
        onSendMessage(values.message);
        form.resetFields();
      }
    }

  };

  return (
    // Verwendung von <> und </> um zwei Elemente zu umschließen
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="chatForm"
        style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}
      >
        <Form.Item
          name="message"
          className="chatForm"
          style={{ flex: 1, marginRight: '8px', marginBottom: 0 }}
        >
          <Input.TextArea
            placeholder="Schreibe eine Nachricht..."
            autoSize={{ minRows: 1, maxRows: 6 }}
            className="chatInput"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
                e.preventDefault();
                form.submit();
              }
            }}
            style={{ borderRadius: '4px' }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="chatButton"
          icon={isLoading ? <StopOutlined /> : <SendOutlined />}
          style={{ whiteSpace: 'nowrap', height: '40px' }}
        >
        </Button>
      </Form>
      <div className='disclaimerContainer'>
        <p className='disclaimer'>
          Azuna AssistantOS kann Fehler machen. Bitte validiere wichtige Daten.
        </p>
      </div>
    </>
  );
}

export default ChatInput;
